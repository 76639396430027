<template>

  <!-- Start Content-->
  <div>

    <transition name="fade" mode="out-in">
      <div class="modal" tabindex="-1" role="dialog" v-show="deleteModal">
        <div class="modal-bg" @click="CancelDeleteReport"></div>
        <div class="modal-dialog">

          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="standard-modalLabel">{{$language('削除確認')}}</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true" @click="CancelDeleteReport">×</button>
            </div>
            <div class="modal-body">
              <p>
                {{$language('削除したレポートは戻すことができません')}}<br>
                {{$language('表示中のレポートを削除しますか？')}}
                
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" @click="CancelDeleteReport">{{$language('キャンセル')}}</button>
              <button type="button" class="btn btn-danger" @click="DeleteReport"><i class="mdi mdi-checkbox-blank-off-outline"></i> {{$language('削除する')}}</button>
            </div>
          </div>

        </div>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div class="modal" tabindex="-1" role="dialog" v-show="gridModal">
        <div class="modal-bg" @click="CancelGridReport"></div>
        <div class="modal-dialog grid-modal-dialog responsive-table-plugin w-100">

          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="standard-modalLabel">{{$language('データ選択')}}</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true" @click="CancelGridReport">×</button>
            </div>
            <div class="modal-body table-responsive">
              <table class="table table-striped text-right">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>{{$language('竣工年月')}}</th>
                    <th>{{$language('規模 - 階数')}}</th>
                    <th>{{$language('規模 - 延床面積')}}</th>
                    <th>{{$language('規模 - 基準階面積')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(gridData, num) in gridList" :key="num" @click="SelectGridDataRow(num)">
                    <td>{{ num + 1 }}</td>
                    <td>{{ gridData.CompletionDate }}</td>
                    <td>{{ gridData.FlrsAbvGrnd }}</td>
                    <td>{{ gridData.TotalGFA }}</td>
                    <td>{{ gridData.Typical_Minimum_FloorSize }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light " @click="CancelGridReport">{{$language('キャンセル')}}</button>
            </div>
          </div>

        </div>
      </div>
    </transition>
    
    <router-link :to="{ name: returnPage }" class="btn btn-primary waves-effect waves-light btn-back-fixed">
      <i class="mdi mdi-arrow-left-bold"></i> {{$language('一覧')}}
    </router-link>

    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="!jsonErr">
      <Form @submit="onSubmit" :validation-schema="schema">

        <div class="row" v-if="saveLoading">
          <div class="col-12">
            <div class="card-box d-flex justify-content-center">
              <div class="spinner-border m-12" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-12">
            <div class="card-box">
              <div class="alert alert-primary" v-if="saveAlert === 1">{{$language('レポートを保存しました')}}</div>
              <div class="alert alert-danger" v-else-if="saveAlert === 9">{{$language('レポートの保存に失敗しました')}}</div>
              <div class="alert alert-danger" v-else-if="saveAlert === 99">{{$language('入力内容に問題があります')}}</div>
              <div class="alert alert-danger" v-if="deleteAlert">{{ deleteAlert }}</div>
              <div class="alert alert-warning" v-if="downloadAlert">{{ downloadAlert }}</div>
              <div class="btn-toolbar justify-content-end mb-0" role="toolbar">
                <div class="d-flex align-items-center mr-0 mr-md-3 mb-1 mb-md-0">{{$language('公開状態')}} : 
                  <button type="button" class="btn btn-success ml-1" v-if="publishState" @click="PrivateReport">&nbsp;&nbsp;{{$language('公開')}}&nbsp;&nbsp;</button>
                  <button type="button" class="btn btn-secondary ml-1" v-if="!publishState" @click="PublishReport">{{$language('非公開')}}</button>
                </div>
                <button type="submit" class="btn btn-primary ml-1 mb-1 mb-md-0" v-if="isValid" @click="saveAlert = 99">{{$language('save')}}</button>
                <button type="button" class="btn btn-primary disabled ml-1 mb-1 mb-md-0" v-if="!isValid">{{$language('save')}}</button>
                <button type="button" class="btn btn-primary ml-1 mb-1 mb-md-0" @click="DownloadReport" v-if="isEdit">{{$language('報告書を見る')}}</button>
                <button type="button" class="btn btn-primary disabled ml-1 mb-1 mb-md-0" v-if="!isEdit">{{$language('報告書を見る')}}</button>
                <button type="button" class="btn btn-danger ml-1 ml-md-5 mb-1 mb-md-0" @click="CancelDeleteReport" v-if="isEdit">{{$language('削除')}}</button>
                <button type="button" class="btn btn-danger disabled ml-1 ml-md-5 mb-1 mb-md-0" v-if="!isEdit">{{$language('削除')}}</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card-box">
              <h2 class="header-title mb-3">1. {{$language('概要表')}}</h2>
              <div class="row mb-4">
                <div class="col-2 col-md-2">
                  {{$language('貸主')}}
                </div>
                <div class="col-10">
                  <div class="row">
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('社名')}}</label>
                        <Field name="lender_company_name" :class="'form-control col-10 col-md-12'" v-model="summary.lender.company_name" />
                        <ErrorMessage class="invalid-feedback" name="lender_company_name" />
                    </div>
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('所在地')}}</label>
                        <Field name="lender_location" :class="'form-control col-10 col-md-12'" v-model="summary.lender.location" />
                        <ErrorMessage class="invalid-feedback" name="lender_location" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('氏名')}}</label>
                        <Field name="lender_name" :class="'form-control col-10 col-md-12'" v-model="summary.lender.name" />
                        <ErrorMessage class="invalid-feedback" name="lender_name" />
                    </div>
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('電話番号')}}</label>
                        <Field name="lender_telephone" type="tel" :class="'form-control col-10 col-md-12'" v-model="summary.lender.telephone" />
                        <ErrorMessage class="invalid-feedback" name="lender_telephone" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-2 col-md-2">
                  {{$language('物件')}}
                </div>
                <div class="col-10">
                  <div class="row">
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('物件名')}}</label>
                        <Field name="lender_property_name" :class="'form-control col-10 col-md-12'" v-model="summary.property.name" />
                        <ErrorMessage class="invalid-feedback" name="lender_property_name" />
                    </div>
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('所在地')}}</label>
                        <Field name="lender_property_location" :class="'form-control col-10 col-md-12'" v-model="summary.property.location" />
                        <ErrorMessage class="invalid-feedback" name="lender_property_location" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12">
                      <div class="d-flex justify-content-center col-10 col-md-12 pt-2" v-if="gridLoading">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <div v-else>
                        <button type="button" @click="GridSearch" class="btn form-control col-10 col-md-12 btn-black">データ取得</button>
                        <p class="mb-1"><code v-if="gridResult">{{ gridResult }}</code></p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('竣工年月')}}</label>
                        <Field name="lender_property_completion_date" :class="'form-control col-10 col-md-12 bg-light'" v-model="summary.property.completion_date" />
                        <ErrorMessage class="invalid-feedback" name="lender_property_completion_date" />
                    </div>
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('規模 - 階数')}}</label>
                        <Field name="lender_property_scale_floors" :class="'form-control col-10 col-md-12 bg-light'" v-model="summary.property.scale.floors" />
                        <ErrorMessage class="invalid-feedback" name="lender_property_scale_floors" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('規模 - 延床面積')}}</label>
                        <Field name="lender_property_scale_total_floor_area" :class="'form-control col-10 col-md-12 bg-light'" v-model="summary.property.scale.total_floor_area" />
                        <ErrorMessage class="invalid-feedback" name="lender_property_scale_total_floor_area" />
                    </div>
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('規模 - 基準階面積')}}</label>
                        <Field name="lender_property_scale_standard_floor_area" :class="'form-control col-10 col-md-12 bg-light'" v-model="summary.property.scale.standard_floor_area" />
                        <ErrorMessage class="invalid-feedback" name="lender_property_scale_standard_floor_area" />
                    </div>
                  </div>
                </div>
                <div class="col-10 offset-2 offset-md-2 mt-3">
                  <div class="row">
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('賃貸区画階数')}}</label>
                        <Field name="property_floor" :class="'form-control col-10 col-md-12'" v-model="summary.property.floor" />
                        <ErrorMessage class="invalid-feedback" name="property_floor" />
                    </div>
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('賃貸区画面積')}}</label>
                        <Field name="property_area" :class="'form-control col-10 col-md-12'" v-model="summary.property.area" />
                        <ErrorMessage class="invalid-feedback" name="property_area" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('表面共益費込')}}</label>
                        <Field name="property_maintenance_fee" :class="'form-control col-10 col-md-12'" v-model="summary.property.maintenance_fee" />
                        <ErrorMessage class="invalid-feedback" name="property_maintenance_fee" />
                    </div>
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('想定現状使用最小面積(業種別)')}}</label>
                        <Field name="property_leasing_area" :class="'form-control col-10 col-md-12'" v-model="summary.property.leasing_area" />
                        <ErrorMessage class="invalid-feedback" name="property_leasing_area" />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('特徴 - BCP')}}</label>
                        <Field name="property_feature_bcp" :class="'form-control col-10 col-md-12'" v-model="summary.property.feature.bcp" />
                        <ErrorMessage class="invalid-feedback" name="property_feature_bcp" />
                    </div>
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('特徴 - 最寄駅')}}</label>
                        <Field name="property_feature_nearest_station" :class="'form-control col-10 col-md-12'" v-model="summary.property.feature.nearest_station" />
                        <ErrorMessage class="invalid-feedback" name="property_feature_nearest_station" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('特徴 - セキュリティ')}}</label>
                        <Field name="property_feature_security" :class="'form-control col-10 col-md-12'" v-model="summary.property.feature.security" />
                        <ErrorMessage class="invalid-feedback" name="property_feature_security" />
                    </div>
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('特徴 - 施設構成')}}</label>
                        <Field name="property_feature_facility_configuration" :class="'form-control col-10 col-md-12'" v-model="summary.property.feature.facility_configuration" />
                        <ErrorMessage class="invalid-feedback" name="property_feature_facility_configuration" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('特徴 - 助成措置')}}</label>
                        <Field name="property_feature_subsidies" :class="'form-control col-10 col-md-12'" v-model="summary.property.feature.subsidies" />
                        <ErrorMessage class="invalid-feedback" name="property_feature_subsidies" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card-box">
              <h2 class="header-title mb-3">2. {{$language('業種別内訳横棒グラフ')}}</h2>
              <div class="row mb-1">
                <div class="col-12 col-md-2 mb-2 mb-md-0">{{$language('対象誘致エリア')}}</div>
              </div>


              <div class="mb-2 card" v-for="(obj, num) in summary.areas_of_graph" :key="num">

                <div class="card-header bg-secondary text-white" @click="ToggleGraphRow(num)">
                  <div class="row align-items-center justify-content-between">
                    <p class="card-title m-0 col-12 col-md-2">{{$language('住所')}}{{num + 1}}</p>
                    <p class="m-0 col-9 col-md-8" v-if="summary.areas_of_graph[num].omr_zone && summary.areas_of_graph[num].omr_zone !== ' '">{{ summary.areas_of_graph[num].omr_zone }}</p>
                    <p class="m-0 col-9 col-md-8" v-else-if="summary.areas_of_graph[num].prefecture"><span>{{ summary.areas_of_graph[num].prefecture }}</span><span v-if="summary.areas_of_graph[num].city">{{ summary.areas_of_graph[num].city }}</span><span v-if="summary.areas_of_graph[num].town">{{ summary.areas_of_graph[num].town }}</span></p>
                    <div class="col-3 col-md-2">
                      <button type="button" class="btn btn-xs py-0 col-sm-8 offset-sm-4 btn-danger form-control waves-effect waves-light" @click.stop="DelGraphRow(num)">
                        <i class="mdi mdi-close"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="card-body bg-light" v-show="showGraphArea[num] && showGraphArea[num].isShow">
                  <div class="row d-flex align-items-center">

                    <div class="col-12 col-md-2 mb-2 mb-md-0 d-flex d-md-block align-items-center">
                      <div class="col-4 col-md-12 px-0">{{$language('都道府県')}}</div>
                      <!-- <select class="form-control col-8 col-md-12" :class="{ 'bg-light': summary.areas_of_graph[num].omr_zone }" v-model="summary.areas_of_graph[num].prefecture" @change="GraphCities(num)">
                        <option v-if="summary.areas_of_graph[num].prefecture">{{ summary.areas_of_graph[num].prefecture }}</option>
                        <option></option>
                        <option v-for="prefecture in prefectures" :key="prefecture">{{ prefecture }}</option>
                      </select> -->
                      <Select2 :id="'graph_prefecture_' + num" v-model="summary.areas_of_graph[num].prefecture" :options="prefectures" @select="GraphCities(num)" :class="{ 'disabled': summary.areas_of_graph[num].omr_zone && summary.areas_of_graph[num].omr_zone !== ' ' }"></Select2>
                    </div>

                    <div class="col-12 col-md-3 mb-2 mb-md-0 d-flex d-md-block align-items-center">
                      <div class="col-4 col-md-12 px-0">{{$language('市、区')}}</div>
                      <!-- <select class="form-control col-8 col-md-12" :class="{ 'bg-light': summary.areas_of_graph[num].omr_zone }" v-model="summary.areas_of_graph[num].city" @change="GraphTowns(num)" @mouseover.once="OnceGraphCities(num)">
                        <option v-if="summary.areas_of_graph[num].city">{{ summary.areas_of_graph[num].city }}</option>
                        <option></option>
                        <option v-for="city in graph.cities[num]" :key="city">{{ city }}</option>
                      </select> -->
                      <Select2 :id="'graph_city_' + num" v-model="summary.areas_of_graph[num].city" :options="graph.cities[num]" @select="GraphTowns(num)" :class="{ 'disabled': summary.areas_of_graph[num].omr_zone && summary.areas_of_graph[num].omr_zone !== ' ' }"></Select2>
                    </div>

                    <div class="col-12 col-md-3 mb-2 mb-md-0 d-flex d-md-block align-items-center">
                      <div class="col-4 col-md-12 px-0">{{$language('町')}}</div>
                      <!-- <select class="form-control col-8 col-md-12" :class="{ 'bg-light': summary.areas_of_graph[num].omr_zone }" v-model="summary.areas_of_graph[num].town" @mouseover.once="OnceGraphTowns(num)">
                        <option v-if="summary.areas_of_graph[num].town">{{ summary.areas_of_graph[num].town }}</option>
                        <option></option>
                        <option v-for="street in graph.streets[num]" :key="street">{{ street }}</option>
                      </select> -->
                      <Select2 :id="'graph_town_' + num" v-model="summary.areas_of_graph[num].town" :options="graph.streets[num]" :class="{ 'disabled': summary.areas_of_graph[num].omr_zone && summary.areas_of_graph[num].omr_zone !== ' ' }"></Select2>
                    </div>

                    <div class="col-12 col-md-1 text-center mb-2 mb-md-0">{{$language('OR')}}</div>

                    <div class="col-12 col-md-3 mb-2 mb-md-0 d-flex d-md-block align-items-center">
                      <div class="col-4 col-md-12 px-0">{{$language('OMRゾーン')}}</div>
                      <!-- <select class="form-control col-8 col-md-12" v-model="summary.areas_of_graph[num].omr_zone">
                        <option v-if="summary.areas_of_graph[num].omr_zone">{{ summary.areas_of_graph[num].omr_zone }}</option>
                        <option></option>
                        <option v-for="omr in omrs" :key="omr">{{ omr }}</option>
                      </select> -->
                      <Select2 :id="'graph_omr_' + num" v-model="summary.areas_of_graph[num].omr_zone" :options="omrs" :class="{ 'disabled': summary.areas_of_graph[num].prefecture && summary.areas_of_graph[num].prefecture !== ' ' && (!summary.areas_of_graph[num].omr_zone || summary.areas_of_graph[num].omr_zone === ' ') }"></Select2>
                    </div>

                  </div>

                </div>
                    
              </div>

              <div class="row mb-2" v-if="!maxGraphRow">
                <div class="col-2 ml-2">
                  <button type="button" class="btn waves-effect waves-light form-control btn-black" @click="AddGraphRow">
                    <i class="mdi mdi-plus"></i>
                  </button>
                </div>
              </div>
              <bar-chart :barData="summary.areas_of_graph" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card-box">
              <h2 class="header-title mb-3">3. {{$language('移転事例リスト')}}</h2>
              <div class="row mb-1">
                <div class="col-12 col-md-2">{{$language('対象誘致エリア')}}</div>
                
                <div class="row d-flex align-items-center col-12 col-md-10">

                  <div class="col-12 col-md-2 mb-2 mb-md-0 d-flex d-md-block align-items-center">
                    <div class="col-4 col-md-12 px-0">{{$language('都道府県')}}</div>
                    <Select2 :id="'list_prefecture_0'" v-model="summary.areas_of_list[0].prefecture" :options="prefectures" @select="ListCities(0)" :class="{ 'disabled': summary.areas_of_list[0].omr_zone && summary.areas_of_list[0].omr_zone !== ' ' }"></Select2>
                  </div>

                  <div class="col-12 col-md-3 mb-2 mb-md-0 d-flex d-md-block align-items-center">
                    <div class="col-4 col-md-12 px-0">{{$language('市、区')}}</div>
                    <Select2 :id="'list_city_0'" v-model="summary.areas_of_list[0].city" :options="list.cities[0]" @select="ListTowns(0)" :class="{ 'disabled': summary.areas_of_list[0].omr_zone && summary.areas_of_list[0].omr_zone !== ' ' }"></Select2>
                  </div>

                  <div class="col-12 col-md-3 mb-2 mb-md-0 d-flex d-md-block align-items-center">
                    <div class="col-4 col-md-12 px-0">{{$language('町')}}</div>
                    <Select2 :id="'list_town_0'" v-model="summary.areas_of_list[0].town" :options="list.streets[0]" :class="{ 'disabled': summary.areas_of_list[0].omr_zone && summary.areas_of_list[0].omr_zone !== ' ' }"></Select2>
                  </div>

                  <div class="col-12 col-md-1 text-center mb-2 mb-md-0">{{$language('OR')}}</div>

                  <div class="col-12 col-md-3 mb-2 mb-md-0 d-flex d-md-block align-items-center">
                    <div class="col-4 col-md-12 px-0">{{$language('OMRゾーン')}}</div>
                    <Select2 :id="'list_omr_0'" v-model="summary.areas_of_list[0].omr_zone" :options="omrs" :class="{ 'disabled': summary.areas_of_list[0].prefecture && summary.areas_of_list[0].prefecture !== ' ' && (!summary.areas_of_list[0].omr_zone || summary.areas_of_list[0].omr_zone === ' ') }"></Select2>
                  </div>

                </div>

              </div>

              <moving-list :movingData="summary.areas_of_list" />

            </div>
          </div>
        </div>
        
        <param-list :parameters="summary.parameters"></param-list>

        <div class="row" v-if="saveLoading">
          <div class="col-12">
            <div class="card-box d-flex justify-content-center">
              <div class="spinner-border m-12" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-12">
            <div class="card-box">
              <div class="alert alert-primary" v-if="saveAlert === 1">{{$language('レポートを保存しました')}}</div>
              <div class="alert alert-danger" v-else-if="saveAlert === 9">{{$language('レポートの保存に失敗しました')}}</div>
              <div class="alert alert-danger" v-else-if="saveAlert === 99">{{$language('入力内容に問題があります')}}</div>
              <div class="alert alert-danger" v-if="deleteAlert">{{ deleteAlert }}</div>
              <div class="alert alert-warning" v-if="downloadAlert">{{ downloadAlert }}</div>
              <div class="btn-toolbar justify-content-end mb-0" role="toolbar">
                <div class="d-flex align-items-center mr-0 mr-md-3 mb-1 mb-md-0">{{$language('公開状態')}} : 
                  <button type="button" class="btn btn-success ml-1" v-if="publishState" @click="PrivateReport">&nbsp;&nbsp;{{$language('公開')}}&nbsp;&nbsp;</button>
                  <button type="button" class="btn btn-secondary ml-1" v-if="!publishState" @click="PublishReport">{{$language('非公開')}}</button>
                </div>
                <button type="submit" class="btn btn-primary ml-1 mb-1 mb-md-0" v-if="isValid" @click="saveAlert = 99">{{$language('save')}}</button>
                <button type="button" class="btn btn-primary disabled ml-1 mb-1 mb-md-0" v-if="!isValid">{{$language('save')}}</button>
                <button type="button" class="btn btn-primary ml-1 mb-1 mb-md-0" @click="DownloadReport" v-if="isEdit">{{$language('報告書を見る')}}</button>
                <button type="button" class="btn btn-primary disabled ml-1 mb-1 mb-md-0" v-if="!isEdit">{{$language('報告書を見る')}}</button>
                <button type="button" class="btn btn-danger ml-1 ml-md-5 mb-1 mb-md-0" @click="CancelDeleteReport" v-if="isEdit">{{$language('削除')}}</button>
                <button type="button" class="btn btn-danger disabled ml-1 ml-md-5 mb-1 mb-md-0" v-if="!isEdit">{{$language('削除')}}</button>
              </div>
            </div>
          </div>
        </div>

      </Form>
    </div>
    <div v-else>
      <code>
        {{$language('データ取得に失敗しました')}}
      </code>
    </div>
      
  </div> <!-- container -->
</template>

<script>
import BarChart from './BarChart.vue'
import MovingList from './MovingList.vue'
import ParamList from './ParamList.vue'
import axios from 'axios'
import router from '@/router'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import Select2 from 'vue3-select2-component'

const { currentRoute } = router;

export default {
  name: 'ReportListCreate',
  components: {
    BarChart,
    MovingList,
    ParamList,
    Form,
    Field,
    ErrorMessage,
    Select2
  },
  props: {
    returnPage: String
  },
  data() {

    return {
      schema:'',
      jsonObj: [Object],
      jsonErr: false,
      isLoading: true,
      isValid: true,
      isEdit: false,
      saveAlert: 0, // 保存結果
      saveLoading: false,
      reportId: '',
      deleteModal: false,
      deleteAlert: '',
      downloadAlert: '',
      publishState: false,
      gridLoading: false,
      gridResult: "",

      gridList: [],
      gridCount: 0,
      gridModal: false,

      userName: document.cookie.replace(/(?:(?:^|.*;\s*)name\s*=\s*([^;]*).*$)|^.*$/, "$1"),

      series: [],
      BuildingID:'',//物件ID
      summary: {
        lender: {
          company_name: "", //社名
          location: "", //所在地
          name: "", //氏名
          telephone: "", //00-0000-0000
        },
        property: {
          name: "", //物件名
          location: "", //所在地
          completion_date: "", //2020-01
          scale: {
            floors: '', //99
            total_floor_area: '', //9999
            standard_floor_area: '' //9999
          },
          floor: '', //10
          area: '', //999
          maintenance_fee: '', //999999
          leasing_area: '', //999
          feature: {
            bcp: "", //BCP
            nearest_station: "", //最寄り駅
            security: "", //セキュリティ
            facility_configuration: "", //施設構成
            subsidies: "" //助成措置
          }
        },
        areas_of_graph: [
          {
            prefecture: "", //都道府県
            city: "", //市区
            town: "", //町
            omr_zone: "" //OMR
          }
        ],
        areas_of_list: [
          {
            prefecture: "", //都道府県
            city: "", //市区
            town: "", //町
            omr_zone: "" //OMR
          }
        ],
        parameters: [
          {
            no: 1, //1
            field: "状況", //分野
            keyword: "業績(売上高)好調", //キーワード
            summary: "", //概要
            find_out: "" //調べ方
          },
          {
            no: 2,
            field: "状況",
            keyword: "業績(売上高)不調",
            summary: "",
            find_out: ""
          },
          {
            no: 3,
            field: "エリア",
            keyword: "ターゲット物件との近接",
            summary: "",
            find_out: ""
          },
          {
            no: 4,
            field: "ビル属性",
            keyword: "現入居ビルの築年数",
            summary: "",
            find_out: ""
          },
          {
            no: 5,
            field: "ビル属性",
            keyword: "現入居ビルのグレード",
            summary: "",
            find_out: ""
          },
          {
            no: 6,
            field: "ビル属性",
            keyword: "現入居ビルの空室面積",
            summary: "",
            find_out: ""
          },
          {
            no: 7,
            field: "ビル属性",
            keyword: "現入居ビルのBOR",
            summary: "",
            find_out: ""
          },
          {
            no: 8,
            field: "ビル属性",
            keyword: "現入居ビルの使用面積",
            summary: "",
            find_out: ""
          }
        ],
        author: "", //作成者
        publish: 0 //公開フラグ1111
      },

      // 都道府県(直書き)
      prefectures: [{id:' ', text:'-'},'北海道','青森県','岩手県','宮城県','秋田県','山形県','福島県','茨城県','栃木県','群馬県','埼玉県','千葉県','東京都','神奈川県','新潟県','富山県','石川県','福井県','山梨県','長野県','岐阜県','静岡県','愛知県','三重県','滋賀県','京都府','大阪府','兵庫県','奈良県','和歌山県','鳥取県','島根県','岡山県','広島県','山口県','徳島県','香川県','愛媛県','高知県','福岡県','佐賀県','長崎県','熊本県','大分県','宮崎県','鹿児島県','沖縄県'],
      graph: {
        choicePrefecture: ['','','','','','',''],
        cities: ['','','','','','',''],
        choiceCity : ['','','','','','',''],
        streets: ['','','','','','','']
      },
      list: {
        choicePrefecture : [''],
        cities: [''],
        choiceCity : [''],
        streets: ['']
      },
      omrs: [''],
      maxGraphRow: false, // 上限
      showGraphArea: [{isShow:true}],
    }
  },
  mounted() {
    this.schema = yup.object({
      lender_company_name: yup.string().nullable().required(this.$language('必須入力です。')).max(255, '255'+this.$language('文字以内で入力してください。')).label(this.$language('社名')),
      lender_location: yup.string().nullable().required(this.$language('必須入力です。')).max(1024, '1024'+this.$language('文字以内で入力してください。')).label(this.$language('所在地')),
      lender_name: yup.string().nullable().required(this.$language('必須入力です。')).max(1000, '1000'+this.$language('文字以内で入力してください。')).label(this.$language('氏名')),
      lender_telephone: yup.string().nullable().required(this.$language('必須入力です。')).matches(/^[a-zA-Z0-9-+]*$/, this.$language('半角英数字で入力してください。')).max(100, '100'+this.$language('文字以内で入力してください。')).label(this.$language('電話番号')),
      lender_property_name: yup.string().nullable().required(this.$language('必須入力です。')).max(500, '500'+this.$language('文字以内で入力してください。')).label(this.$language('物件名')),
      lender_property_location: yup.string().nullable().required(this.$language('必須入力です。')).max(1024, '1024'+this.$language('文字以内で入力してください。')).label(this.$language('所在地')),
      lender_property_completion_date: yup.string().nullable().matches(/^[a-zA-Z0-9-/]*$/, this.$language('半角英数字で入力してください。')).max(10, '10'+this.$language('文字以内で入力してください。')).label(this.$language('竣工年月')),
      lender_property_scale_floors: yup.string().nullable().matches(/^[a-zA-Z0-9]*$/, this.$language('半角英数字で入力してください。')).max(10, '10'+this.$language('文字以内で入力してください。')).label(this.$language('規模 - 階数')),
      lender_property_scale_total_floor_area: yup.string().nullable().matches(/^[0-9.]*$/, '半角数字で入力してください。').label(this.$language('規模 - 延床面積')),
      lender_property_scale_standard_floor_area: yup.string().nullable().matches(/^[0-9.]*$/, '半角数字で入力してください。').label(this.$language('規模 - 基準階面積')),
      property_floor: yup.string().nullable().required(this.$language('必須入力です。')).matches(/^[a-zA-Z0-9-/]*$/, this.$language('半角英数字で入力してください。')).max(10, '10'+this.$language('文字以内で入力してください。')).label(this.$language('賃貸区画階数')),
      property_area: yup.string().nullable().required(this.$language('必須入力です。')).matches(/^[0-9.]*$/, '半角数字で入力してください。').label(this.$language('賃貸区画面積')),
      property_maintenance_fee: yup.string().nullable().required(this.$language('必須入力です。')).matches(/^[0-9]*$/, '半角数字で入力してください。').label(this.$language('表面共益費込')),
      property_leasing_area: yup.string().nullable().required(this.$language('必須入力です。')).matches(/^[0-9.]*$/, '半角数字で入力してください。').label(this.$language('想定現状使用最小面積(業種別)')),
      property_feature_bcp: yup.string().nullable().required(this.$language('必須入力です。')).max(500, '500'+this.$language('文字以内で入力してください。')).label(this.$language('特徴 - BCP')),
      property_feature_nearest_station: yup.string().nullable().required(this.$language('必須入力です。')).max(500, '500'+this.$language('文字以内で入力してください。')).label(this.$language('特徴 - 最寄駅')),
      property_feature_security: yup.string().nullable().required(this.$language('必須入力です。')).max(500, '500'+this.$language('文字以内で入力してください。')).label(this.$language('特徴 - セキュリティ')),
      property_feature_facility_configuration: yup.string().nullable().required(this.$language('必須入力です。')).max(500, '500'+this.$language('文字以内で入力してください。')).label(this.$language('特徴 - 施設構成')),
      property_feature_subsidies: yup.string().nullable().required(this.$language('必須入力です。')).max(500, '500'+this.$language('文字以内で入力してください。')).label(this.$language('特徴 - 助成措置')),
    });
    if( currentRoute.value.params.dataId ) {
      this.reportId = currentRoute.value.params.dataId
    } else {
      this.isLoading = false
    }

    // edit
    if( this.reportId ) {
      this.isEdit = true,
      axios
        .get('/v1/basic-reports/' + this.reportId)
        .then(response => {
          this.BuildingID=response.data.BuildingID;
          this.summary = response.data.summary
          //公開ステータス確認
          if( response.data.summary.publish === 1 ) {
            this.publishState = true
          } else {
            this.publishState = false
          }
          //グラフ用住所欄のアコーディオン用
          for( let i = 0; i < this.summary.areas_of_graph.length; i++ ) {
            this.showGraphArea[i] = {isShow:true}
            this.OnceGraphPrefectures()
            this.OnceOMRs()
            this.OnceGraphCities(i)
            this.OnceGraphTowns(i)
          }
          this.$nextTick(() => {
            this.OnceListCities(0)
            this.OnceListTowns(0)
            this.isLoading = false
          })
        })
        .catch(error => {
            this.isLoading = false
            console.log(error),
            this.jsonErr = true
          }
        );
    }

    //omr
    axios.get('/v1/omrs')
    .then(response => {
      this.omrs = response.data.data
      this.omrs.unshift({id:' ', text:'-'})
    })

  },
  methods: {
    onSubmit() {
      this.saveLoading = true
      this.saveAlert = 0
      if( this.reportId ) {
        axios
          .put('/v1/basic-reports/' + this.reportId, {
            summary: this.summary
          })
          .then(() => {
              this.saveAlert = 1
              this.$nextTick(() => {
                this.saveLoading = false
              })
            }
          )
          .catch(error => {
              console.log(error)
              this.saveAlert = 9
              this.saveLoading = false
            }
          );
          this.$nextTick(() => {
            window.parent.postMessage(['loaded'], "*");
          })
      } else {
        axios
          .post('/v1/basic-reports', {
            summary: this.summary,
            name:localStorage.getItem('name'),
          })
          .then(response => {
              this.reportId = response.data.id
              this.$router.push('edit/' + this.reportId)
              this.saveAlert = 1
              this.saveLoading = false
            }
          )
          .catch(error => {
              console.log(error)
              this.saveAlert = 9
              this.saveLoading = false
            }
          );
        this.isEdit = true
        this.$nextTick(() => {
          window.parent.postMessage(['loaded'], "*");
        })
      }
    },
    
    SaveReport: function() {
      this.saveLoading = true
      this.saveAlert = 0
      if( this.reportId ) {
        axios
          .put('/v1/basic-reports/' + this.reportId, {
            summary: this.summary
          })
          .then(() => {
              this.saveAlert = 1
              this.$nextTick(() => {
                this.saveLoading = false
              })
            }
          )
          .catch(error => {
              console.log(error)
              this.saveAlert = 9
              this.saveLoading = false
            }
          );
          this.$nextTick(() => {
            window.parent.postMessage(['loaded'], "*");
          })
      } else {
        axios
          .post('/v1/basic-reports', {
            summary: this.summary
          })
          .then(response => {
              this.reportId = response.data.id
              this.$router.push('edit/' + this.reportId)
              this.saveAlert = 1
              this.saveLoading = false
            }
          )
          .catch(error => {
              console.log(error)
              this.saveAlert = 9
              this.saveLoading = false
            }
          );
        this.isEdit = true
        this.$nextTick(() => {
          window.parent.postMessage(['loaded'], "*");
        })
      }
    },
    PrivateReport: function() {
      this.summary.publish = 0
      this.publishState = false
    },
    PublishReport: function() {
      this.summary.publish = 1
      this.publishState = true
    },
    DownloadReport: function() {
      location.href='/#/build_info?BuildingID='+this.BuildingID+'&tolink=report';
      /*let xmldata = ''

      if( this.reportId ) {
        axios
          .get('/v1/basic-reports/' + this.reportId + '/download/', {
            responseType: 'blob'
          })
          .then(response => (
              xmldata = response.data,
              this.$nextTick(() => {
                let blob = new Blob([xmldata])
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'report.xlsx'
                link.click()
              })
            )
          )
          .catch(error => {
              this.downloadAlert = 'ダウンロードに失敗しました。'
              console.log(error)
            }
          );
      }*/
    },
    CancelDeleteReport: function() {
      this.deleteModal = !this.deleteModal
    },
    DeleteReport: function() {
      if( this.reportId ) {
        axios
          .delete('/v1/basic-reports/' + this.reportId, {
            summary: this.summary,
            author: this.userName
          })
          .then(() => {
            sessionStorage.reportPaged = 1 // 一覧の1頁目に戻す
            this.$router.push({
              name: this.returnPage,
              query: { deleteFlg: 1 }
            })
          }
          )
          .catch(error => {
              this.deleteAlert = this.$language('レポートの削除に失敗しました。')
              this.deleteModal = false
              console.log(error)
            }
          );
      } else {
        this.summary = {
          lender: {
            company_name: "", //社名
            location: "", //所在地
            name: "", //氏名
            telephone: "", //00-0000-0000
          },
          property: {
            name: "", //物件名
            location: "", //所在地
            completion_date: "", //2020-01
            scale: {
              floors: '', //99
              total_floor_area: '', //9999
              standard_floor_area: '' //9999
            },
            floor: '', //10
            area: '', //999
            maintenance_fee: '', //999999
            leasing_area: '', //999
            feature: {
              bcp: "", //BCP
              nearest_station: "", //最寄り駅
              security: "", //セキュリティ
              facility_configuration: "", //施設構成
              subsidies: "" //助成措置
            }
          },
          areas_of_graph: [
            {
              prefecture: "", //都道府県
              city: "", //市区
              town: "", //町
              omr_zone: "" //OMR
            }
          ],
          areas_of_list: [
            {
              prefecture: "", //都道府県
              city: "", //市区
              town: "", //町
              omr_zone: "" //OMR
            }
          ],
          parameters: [
            {
              no: 1, //1
              field: "状況", //分野
              keyword: "業績(売上高)好調", //キーワード
              summary: "", //概要
              find_out: "" //調べ方
            },
            {
              no: 2,
              field: "状況",
              keyword: "業績(売上高)不調",
              summary: "",
              find_out: ""
            },
            {
              no: 3,
              field: "エリア",
              keyword: "ターゲット物件との近接",
              summary: "",
              find_out: ""
            },
            {
              no: 4,
              field: "ビル属性",
              keyword: "現入居ビルの築年数",
              summary: "",
              find_out: ""
            },
            {
              no: 5,
              field: "ビル属性",
              keyword: "現入居ビルのグレード",
              summary: "",
              find_out: ""
            },
            {
              no: 6,
              field: "ビル属性",
              keyword: "現入居ビルの空室面積",
              summary: "",
              find_out: ""
            },
            {
              no: 7,
              field: "ビル属性",
              keyword: "現入居ビルのBOR",
              summary: "",
              find_out: ""
            },
            {
              no: 8,
              field: "ビル属性",
              keyword: "現入居ビルの使用面積",
              summary: "",
              find_out: ""
            }
          ],
          author: "", //作成者
          publish: 0 //公開フラグ
        }
      }
    },


    //grid検索
    GridSearch: function() {
      // 入力チェック
      if( this.summary.property.name && this.summary.property.location ) {
        this.gridLoading = true,
        axios
          .get('/v1/grids', {
            params: {
              name: this.summary.property.name, //固定
              address: this.summary.property.location, //表示件数
            }
          })
          .then(response => { 
            this.gridList = response.data.data
            if(response.data.count == 1) {
              this.summary.property.completion_date = this.gridList[0].CompletionDate
              this.summary.property.scale.floors = this.gridList[0].FlrsAbvGrnd
              this.summary.property.scale.total_floor_area = this.gridList[0].TotalGFA
              this.summary.property.scale.standard_floor_area = this.gridList[0].Typical_Minimum_FloorSize
              this.gridResult = ""
              this.gridLoading = false
            } else {
              this.gridModal = true // 選択肢を表示
              this.gridResult = ""
              this.gridLoading = false
            }
          }
          )
          // 0件
          .catch(() => {
            this.gridResult = this.$language('一致するデータが見つかりません。'),
            this.gridLoading = false
            }
          );
      } else {
        this.gridResult = this.$language('物件名と所在地を入力してください。')
      }
    },
    //Grid選択モーダルを閉じる
    CancelGridReport: function() {
      this.gridModal = false
    },
    //Grid選択モーダルのデータ選択
    SelectGridDataRow: function(num) {
      this.summary.property.completion_date = this.gridList[num].CompletionDate
      this.summary.property.scale.floors = this.gridList[num].FlrsAbvGrnd
      this.summary.property.scale.total_floor_area = this.gridList[num].TotalGFA
      this.summary.property.scale.standard_floor_area = this.gridList[num].Typical_Minimum_FloorSize
      this.gridModal = false
    },


    //グラフ用 市町村取得
    GraphCities: function(val) {
      if( this.summary.areas_of_graph[val].prefecture !== ' ' ) {
        if( this.graph.choicePrefecture[val] !== this.summary.areas_of_graph[val].prefecture ) {
          this.graph.choicePrefecture[val] = this.summary.areas_of_graph[val].prefecture
          this.graph.choiceCity[val] = ''
          this.summary.areas_of_graph[val].city = ''
          this.graph.streets[val] = []
          this.summary.areas_of_graph[val].town = ''
          axios
            .get('/v1/cities', {
              params: {
                state: this.summary.areas_of_graph[val].prefecture, 
              }
            })
            .then(response => {
                this.graph.cities[val] = response.data.data
                this.graph.cities[val].unshift({id:' ', text:'-'})
              }
            )
            .catch(() => {
              console.log('Not applicable')
              // this.jsonErr = true
              }
            );
        }
      } else {
          this.graph.choicePrefecture[val] = ''
          this.graph.choiceCity[val] = ''
          this.graph.cities[val] = [{id:' ', text:'-'}]
          this.summary.areas_of_graph[val].city = ''
          this.graph.streets[val] = [{id:' ', text:'-'}]
          this.summary.areas_of_graph[val].town = ''
      }
    },
    //グラフ用 町取得
    GraphTowns: function(val) {
      if( this.summary.areas_of_graph[val].city ) {
        if( this.graph.choiceCity[val] !== this.summary.areas_of_graph[val].city ) {
          this.graph.choiceCity[val] = this.summary.areas_of_graph[val].city
          this.summary.areas_of_graph[val].town = ''
          axios
            .get('/v1/streets', {
              params: {
                state: this.summary.areas_of_graph[val].prefecture,
                city: this.summary.areas_of_graph[val].city,
              }
            })
            .then(response => {
                this.graph.streets[val] = response.data.data
                this.graph.streets[val].unshift({id:' ', text:'-'})
              }
            )
            .catch(() => {
              console.log('Not applicable')
              // this.jsonErr = true
              }
            );
        }
      } else {
          this.graph.choiceCity[val] = ''
          this.graph.streets[val] = [{id:' ', text:'-'}]
          this.summary.areas_of_graph[val].town = ''
      }
    },
    //グラフ用 初回 都道府県取得(既にデータがある場合)
    OnceGraphPrefectures: function() {
      axios
        .get('/v1/states')
        .then(response => {
            this.prefectures = response.data.data
            this.prefectures.unshift({id:' ', text:'-'})
          }
        )
        .catch(() => {
          console.log('Not applicable')
          // this.jsonErr = true
          }
        );
    },
    //グラフ用 初回 市町村取得(既にデータがある場合)
    OnceGraphCities: function(val) {
      if( !this.graph.cities[val] && this.summary.areas_of_graph[val].prefecture ) {
        axios
          .get('/v1/cities', {
            params: {
              state: this.summary.areas_of_graph[val].prefecture, 
            }
          })
          .then(response => {
              this.graph.cities[val] = response.data.data
              this.graph.cities[val].unshift({id:' ', text:'-'})
            }
          )
          .catch(() => {
            console.log('Not applicable')
            // this.jsonErr = true
            }
          );
      }
    },
    //グラフ用 初回 町取得(既にデータがある場合)
    OnceGraphTowns: function(val) {
      if( !this.graph.streets[val] && this.summary.areas_of_graph[val].city ) {
        axios
          .get('/v1/streets', {
            params: {
              state: this.summary.areas_of_graph[val].prefecture,
              city: this.summary.areas_of_graph[val].city,
            }
          })
          .then(response => {
              this.graph.streets[val] = response.data.data
              this.graph.streets[val].unshift({id:' ', text:'-'})
            }
          )
          .catch(() => {
            console.log('Not applicable')
            // this.jsonErr = true
            }
          );
      }
    },
    //グラフ用 初回 OMR取得(既にデータがある場合)
    OnceOMRs: function() {
        axios
          .get('/v1/omrs')
          .then(response => {
              this.omrs = response.data.data
              this.omrs.unshift({id:' ', text:'-'})
            }
          )
          .catch(() => {
            console.log('Not applicable')
            // this.jsonErr = true
            }
          );

    },
    //グラフ用 住所行削除
    DelGraphRow: function(val) {
      this.summary.areas_of_graph.splice(val,1)
      this.showGraphArea.splice(val,1)

      // 0件になる場合は空行を追加して1件に戻す
      if(this.summary.areas_of_graph.length === 0) {
        this.summary.areas_of_graph.push(
          {
            prefecture: "", //都道府県
            city: "", //市区
            town: "", //町
            omr_zone: "" //OMR
          }
        )
        this.showGraphArea.push({isShow: true})
      }
      
      this.$nextTick(() => {
        window.parent.postMessage(['loaded'], "*");
      })
    },
    //グラフ用 住所行追加
    AddGraphRow: function() {
      this.summary.areas_of_graph.push(
        {
          prefecture: "", //都道府県
          city: "", //市区
          town: "", //町
          omr_zone: "" //OMR
        }
      )
      this.showGraphArea.push({isShow: true})
      this.$nextTick(() => {
        window.parent.postMessage(['loaded'], "*");
      })
    },
    //アコーディオン
    ToggleGraphRow: function(num) {
      if(this.showGraphArea[num]) {
        this.showGraphArea[num].isShow = !this.showGraphArea[num].isShow
      } else {
        this.showGraphArea[num] = {isShow:true}
      }
      this.$nextTick(() => {
        window.parent.postMessage(['loaded'], "*");
      })
    },
    //事例リスト用 市町村取得
    ListCities: function(val) {
      if( this.summary.areas_of_list[val].prefecture ) {
        if( this.list.choicePrefecture[val] !== this.summary.areas_of_list[val].prefecture ) {
          this.list.choicePrefecture[val] = this.summary.areas_of_list[val].prefecture
          this.list.choiceCity[val] = ''
          this.summary.areas_of_list[val].city = ''
          this.list.streets[val] = []
          this.summary.areas_of_list[val].town = ''
          axios
            .get('/v1/cities', {
              params: {
                state: this.summary.areas_of_list[val].prefecture, 
              }
            })
            .then(response => {
                this.list.cities[val] = response.data.data
                this.list.cities[val].unshift({id:' ', text:'-'})
              }
            )
            .catch(() => {
              console.log('Not applicable')
              // this.jsonErr = true
              }
            );
        }
      } else {
          this.list.choicePrefecture[val] = ''
          this.list.choiceCity[val] = ''
          this.list.cities[val] = [{id:' ', text:'-'}]
          this.summary.areas_of_list[val].city = ''
          this.list.streets[val] = [{id:' ', text:'-'}]
          this.summary.areas_of_list[val].town = ''
      }
    },
    //事例リスト用 町取得
    ListTowns: function(val) {
      if( this.summary.areas_of_list[val].city ) {
        if( this.list.choiceCity[val] !== this.summary.areas_of_list[val].city ) {
          this.list.choiceCity[val] = this.summary.areas_of_list[val].city
          this.summary.areas_of_list[val].town = ''
          axios
            .get('/v1/streets', {
              params: {
                state: this.summary.areas_of_list[val].prefecture,
                city: this.summary.areas_of_list[val].city,
              }
            })
            .then(response => {
                this.list.streets[val] = response.data.data
                this.list.streets[val].unshift({id:' ', text:'-'})
              }
            )
            .catch(() => {
              console.log('Not applicable')
              // this.jsonErr = true
              }
            );
        }
      } else {
          this.list.choiceCity[val] = ''
          this.list.streets[val] = [{id:' ', text:'-'}]
          this.summary.areas_of_list[val].town = ''
      }
    },
    //事例リスト用 初回 市町村取得(既にデータがある場合)
    OnceListCities: function(val) {
      if( !this.list.cities[val] && this.summary.areas_of_list[val].prefecture ) {
        axios
          .get('/v1/cities', {
            params: {
              state: this.summary.areas_of_list[val].prefecture, 
            }
          })
          .then(response => {
              this.list.cities[val] = response.data.data
              this.list.cities[val].unshift({id:' ', text:'-'})
            }
          )
          .catch(() => {
            console.log('Not applicable')
            // this.jsonErr = true
            }
          );
      }
    },
    //事例リスト用 初回 町取得(既にデータがある場合)
    OnceListTowns: function(val) {
      if( !this.list.streets[val] && this.summary.areas_of_list[val].city ) {
        axios
          .get('/v1/streets', {
            params: {
              state: this.summary.areas_of_list[val].prefecture,
              city: this.summary.areas_of_list[val].city,
            }
          })
          .then(response => {
              this.list.streets[val] = response.data.data
              this.list.streets[val].unshift({id:' ', text:'-'})
            }
          )
          .catch(() => {
            console.log('Not applicable')
            // this.jsonErr = true
            }
          );
      }
    },
    // //事例リスト用 住所行削除
    // DelListRow: function(val) {
    //   this.summary.areas_of_list.splice(val,1)
    // },
    // //事例リスト用 住所行追加
    // AddListRow: function() {
    //   this.summary.areas_of_list.push(
    //     {
    //       prefecture: "", //都道府県
    //       city: "", //市区
    //       town: "", //町
    //       omr_zone: "" //OMR
    //     }
    //   )
    // }
    
  }
}
</script>

<style lang="scss">

.btn-black{
  background-color: #003f2d;
  color: #fff;
}
.disabled .select2-selection--single {
  background: #dfdfdf !important;
}
</style>

<style lang="scss" scoped>
.valid-feedback,
.invalid-feedback {
  display: block !important;
}

.btn-back-fixed {
  position: fixed;
  right: 0;
  top: 16px;
  z-index: 255;
}

.apexcharts-legend.position-bottom.apexcharts-align-center,
.apexcharts-legend.position-top.apexcharts-align-center {
  width: 30.5%;
 }

.modal {
  display: block;
}

.modal-bg {
  background: rgba(50, 58, 70, .5);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 0;
}

.grid-modal-dialog {
  max-width: 640px;
  white-space: nowrap;
  & tbody tr {
    cursor: pointer;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    &:hover {
      color: #343a40;
      background-color: #dae6ec;
      border-color: #d1e0e8;
    }
  }
}

@media screen and (max-width: 768px) {

  .grid-modal-dialog {
    margin: {
      left: auto;
      right: auto;
      top: 2.5rem;
    }
    max-width: 80vw;
  }

}
</style>
