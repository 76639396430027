<template>

  <!-- Start Content-->
  <div>

    <transition name="fade" mode="out-in">
      <div class="modal" tabindex="-1" role="dialog" v-show="deleteModal">
        <div class="modal-bg" @click="CancelDeleteReport"></div>
        <div class="modal-dialog">

          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="standard-modalLabel">{{$language('削除確認')}}</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                @click="CancelDeleteReport">×</button>
            </div>
            <div class="modal-body">
              <p>
                {{$language('削除したレポートは戻すことができません')}}<br>
                {{$language('表示中のレポートを削除しますか')}}
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" @click="CancelDeleteReport">{{$language('キャンセル')}}</button>
              <button type="button" class="btn btn-danger" @click="DeleteReport"><i
                  class="mdi mdi-checkbox-blank-off-outline"></i> {{$language('削除する')}}</button>
            </div>
          </div>

        </div>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div class="modal" tabindex="-1" role="dialog" v-show="gridModal">
        <div class="modal-bg" @click="CancelGridReport"></div>
        <div class="modal-dialog grid-modal-dialog responsive-table-plugin w-100">

          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="standard-modalLabel">{{$language('データ選択')}}</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                @click="CancelGridReport">×</button>
            </div>
            <div class="modal-body table-responsive">
              <table class="table table-striped text-right">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>{{$language('物件名')}}</th>
                    <th>{{$language('所在地')}}</th>
                    <th>{{$language('竣工年月')}}</th>
                    <th>{{$language('規模 - 階数')}}</th>
                    <th>{{$language('規模 - 延床面積')}}</th>
                    <th>{{$language('規模 - 基準階面積')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(gridData, num) in gridList" :key="num" @click="SelectGridDataRow(num)">
                    <td>{{ num + 1 }}</td>
                    <td>{{ gridData.BuildingName }}</td>
                    <td>{{ gridData.address }}</td>
                    <td>{{ gridData.CompletionDate }}</td>
                    <td>{{ gridData.FlrsAbvGrnd }}</td>
                    <td>{{ gridData.TotalGFA }}</td>
                    <td>{{ gridData.Typical_Minimum_FloorSize }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" @click="CancelGridReport">{{$language('キャンセル')}}</button>
            </div>
          </div>

        </div>
      </div>
    </transition>

    <router-link :to="{ name: returnPage }" class="btn btn-primary waves-effect waves-light btn-back-fixed">
      <i class="mdi mdi-arrow-left-bold"></i> {{$language('一覧')}}
    </router-link>

    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="!jsonErr">
      <Form @submit="onSubmit" :validation-schema="schema">

        <div class="row" v-if="saveLoading">
          <div class="col-12">
            <div class="card-box d-flex justify-content-center">
              <div class="spinner-border m-12" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-12">
            <div class="card-box">

              <div class="alert alert-primary" v-if="saveAlert === 1">{{$language('レポートを保存しました')}}</div>
              <div class="alert alert-danger" v-else-if="saveAlert === 9">{{$language('レポートの保存に失敗しました')}}</div>
              <div class="alert alert-danger" v-else-if="saveAlert === 99">{{$language('入力内容に問題があります')}}</div>
              <div class="alert alert-danger" v-if="deleteAlert">{{ deleteAlert }}</div>
              <div class="alert alert-warning" v-if="downloadAlert">{{ downloadAlert }}</div>
              <div class="btn-toolbar justify-content-end mb-0" role="toolbar">
                <a href="#/report" class="btn btn-primary waves-effect waves-light btn-back-fixed"><i
                    class="mdi mdi-arrow-left-bold"></i> {{$language('一覧')}} </a>
                <div class="d-flex align-items-center mr-0 mr-md-3 mb-1 mb-md-0">{{$language('公開状態')}} :
                  <button type="button" class="btn btn-success ml-1" v-if="publishState"
                    @click="PrivateReport">&nbsp;&nbsp;{{$language('公開')}}&nbsp;&nbsp;</button>
                  <button type="button" class="btn btn-secondary ml-1" v-if="!publishState"
                    @click="PublishReport">{{$language('非公開')}}</button>
                </div>
                <button type="submit" class="btn btn-primary ml-1 mb-1 mb-md-0" v-if="isValid"
                  @click="saveAlert = 99">{{$language('save')}}</button>
                <button type="button" class="btn btn-primary disabled ml-1 mb-1 mb-md-0" v-if="!isValid">{{$language('save')}}</button>
                <button type="button" class="btn btn-primary ml-1 mb-1 mb-md-0" @click="DownloadReport"
                  v-if="isEdit">{{$language('報告書を見る')}}</button>
                <button type="button" class="btn btn-primary disabled ml-1 mb-1 mb-md-0" v-if="!isEdit">{{$language('報告書を見る')}}</button>
                <button type="button" class="btn btn-danger ml-1 ml-md-5 mb-1 mb-md-0" @click="CancelDeleteReport"
                  v-if="isEdit">{{$language('削除')}}</button>
                <button type="button" class="btn btn-danger disabled ml-1 ml-md-5 mb-1 mb-md-0"
                  v-if="!isEdit">{{$language('削除')}}</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card-box">
              <h2 class="header-title mb-3">1. {{$language('概要表')}}</h2>
              <div class="row mb-4">
                <div class="col-2 col-md-2">
                  {{$language('貸主')}}
                </div>
                <div class="col-10">
                  <div class="row">
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('社名')}}</label>
                      <Field name="lender_company_name" :class="'form-control col-10 col-md-12'"
                        v-model="summary.lender.company_name" />
                      <ErrorMessage class="invalid-feedback" name="lender_company_name" />
                    </div>
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('所在地')}}</label>
                      <Field name="lender_location" :class="'form-control col-10 col-md-12'"
                        v-model="summary.lender.location" />
                      <ErrorMessage class="invalid-feedback" name="lender_location" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('氏名')}}</label>
                      <Field name="lender_name" :class="'form-control col-10 col-md-12'"
                        v-model="summary.lender.name" />
                      <ErrorMessage class="invalid-feedback" name="lender_name" />
                    </div>
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('連絡')}}</label>
                      <Field name="lender_telephone" type="tel" :class="'form-control col-10 col-md-12'"
                        v-model="summary.lender.telephone" />
                      <ErrorMessage class="invalid-feedback" name="lender_telephone" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-2 col-md-2">
                  {{$language('物件')}}
                </div>
                <div class="col-10">
                  <div class="row">
                    <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">{{$language('物件名/所在地')}}</label>
                      <Field name="lender_property_name_search" :class="'form-control col-10 col-md-12'"
                        v-model="name_search" />

                    </div>
                    <!-- <div class="form-group col-12 col-md-6">
                      <label class="col-form-label pt-0 pb-0">所在地</label>
                      <Field name="lender_property_location_search" :class="'form-control col-10 col-md-12'"
                        v-model="location_search" />

                    </div> -->
                  </div>
                  <div class="row">
                    <div class="form-group col-12">
                      <div class="d-flex justify-content-center col-10 col-md-12 pt-2" v-if="gridLoading">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <div v-else>
                        <button type="button" @click="GridSearch"
                          class="btn  form-control col-md-4 btn-black">{{$language('データ取得')}}</button>
                        <p class="mb-1"><code v-if="gridResult">{{ gridResult }}</code></p>
                      </div>
                    </div>
                  </div>
                  <div v-show="summary.property.name != ''">
                    <div class="row">
                      <div class="form-group col-12 col-md-6">
                        <label class="col-form-label pt-0 pb-0">{{$language('物件名')}}</label>
                        <Field name="lender_property_name" :class="'form-control col-10 col-md-12 bg-light'"
                          v-model="summary.property.name" />
                        <ErrorMessage class="invalid-feedback" name="lender_property_completion_name" />
                      </div>
                      <div class="form-group col-12 col-md-6">
                        <label class="col-form-label pt-0 pb-0">{{$language('所在地')}}</label>
                        <Field name="lender_property_location" :class="'form-control col-10 col-md-12 bg-light'"
                          v-model="summary.property.location" />
                        <ErrorMessage class="invalid-feedback" name="lender_property_location" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-12 col-md-6">
                        <label class="col-form-label pt-0 pb-0">{{$language('竣工年月')}}</label>
                        <Field name="lender_property_completion_date" :class="'form-control col-10 col-md-12 bg-light'"
                          v-model="summary.property.completion_date" />
                        <ErrorMessage class="invalid-feedback" name="lender_property_completion_date" />
                      </div>
                      <div class="form-group col-12 col-md-6">
                        <label class="col-form-label pt-0 pb-0">{{$language('規模 - 階数')}}</label>
                        <Field name="lender_property_scale_floors" :class="'form-control col-10 col-md-12 bg-light'"
                          v-model="summary.property.scale.floors" />
                        <ErrorMessage class="invalid-feedback" name="lender_property_scale_floors" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-12 col-md-6">
                        <label class="col-form-label pt-0 pb-0">{{$language('規模 - 延床面積')}}</label>
                        <Field name="lender_property_scale_total_floor_area"
                          :class="'form-control col-10 col-md-12 bg-light'"
                          v-model="summary.property.scale.total_floor_area" />
                        <ErrorMessage class="invalid-feedback" name="lender_property_scale_total_floor_area" />
                      </div>
                      <div class="form-group col-12 col-md-6">
                        <label class="col-form-label pt-0 pb-0">{{$language('規模 - 基準階面積')}}</label>
                        <Field name="lender_property_scale_standard_floor_area"
                          :class="'form-control col-10 col-md-12 bg-light'"
                          v-model="summary.property.scale.standard_floor_area" />
                        <ErrorMessage class="invalid-feedback" name="lender_property_scale_standard_floor_area" />
                      </div>
                    </div>
                  </div>
                  <div class="col-10 mt-3">
                    <div class="row">
                      <div class="form-group col-12 col-md-6">
                        <label class="col-form-label pt-0 pb-0">{{$language('賃貸区画階数')}}</label>
                        <Field name="property_floor" :class="'form-control col-10 col-md-12'"
                          v-model="summary.property.floor" />
                        <ErrorMessage class="invalid-feedback" name="property_floor" />
                      </div>
                      <div class="form-group col-12 col-md-6">
                        <label class="col-form-label pt-0 pb-0">{{$language('賃貸区画階数')}}</label>
                        <Field name="property_area" :class="'form-control col-10 col-md-12'"
                          v-model="summary.property.area" />
                        <ErrorMessage class="invalid-feedback" name="property_area" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-12 col-md-6">
                        <label class="col-form-label pt-0 pb-0">{{$language('表面共益費込')}}</label>
                        <Field name="property_maintenance_fee" :class="'form-control col-10 col-md-12'"
                          v-model="summary.property.maintenance_fee" />
                        <ErrorMessage class="invalid-feedback" name="property_maintenance_fee" />
                      </div>

                    </div>
                    <div class="row mt-3">

                      <div class="form-group col-12 col-md-6">
                        <label class="col-form-label pt-0 pb-0">{{$language('特徴 - 最寄駅')}}</label>
                        <Field name="property_feature_nearest_station" :class="'form-control col-10 col-md-12'"
                          v-model="summary.property.feature.nearest_station" />
                        <ErrorMessage class="invalid-feedback" name="property_feature_nearest_station" />
                      </div>

                      <div class="form-group col-12 col-md-6">
                        <label class="col-form-label pt-0 pb-0">{{$language('特徴 - 業種')}}</label>
                        <select class="form-control" v-model="summary.property.industry" multiple>
                          <option value="">{{ lan.all }}</option>
                          <option v-for="(item, index) in industryData" :key="index" :value="item">{{ item }}</option>
                        </select>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card-box">
              <h2 class="header-title mb-3">2. {{$language('業種別内訳横棒グラフ')}}</h2>
              <div class="col-lg-12">
                <div class="search-result-box">

                  <div class="tab-content" style="width:100%;background:#fff">
                    <div style="width: 20%;float:left;">


                      <div class="lang" v-html="lan.address"></div>:<br>
                      <input class="form-control" id="address"
                        style="width:200px;display:inline-block;flex: none !important;" type="text" placeholder="Search"
                        aria-label="Search" v-model="address">

                      <div style="width: 100%;height:10px"></div>
                      <div class="lang" v-html="lan.distance"></div>: (m) <div class="help">
                        <ctooltip :text="'<i class=\'mdi mdi-help-circle-outline\'></i>'" :align="'right'"
                          :help_text="lan.help_cbreai_distance" />
                      </div><input class="form-control mr-1"
                        style="width:90px !important; margin-left: 23px;display:inline-block; flex: none !important;"
                        type="text" placeholder="Distance" aria-label="Distance" v-model="distance">


                      <div style="width: 100%;height:10px"></div>
                      <span class="search-btn">
                        <button type="button" class="btn waves-effect waves-light btn-black" @click="search"><i
                            class="fa fa-search mr-1"></i>
                          <div class="lang" v-html="lan.search"></div>
                        </button>
                      </span>
                    </div> 
                    <div class="embed-responsive-16by9 tab-pane show active tc1"
                      style="width: 80%;position:relative; float:left;" id="tc1">
                      <div class="d-flex justify-content-center p-5 loading" v-if="isLoading">
                        <div class="spinner-border m-12" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <div class=" mb-2" style="position:relative">

                        <div id="googleMap" class="google"></div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row" v-if="saveLoading">
          <div class="col-12">
            <div class="card-box d-flex justify-content-center">
              <div class="spinner-border m-12" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-12">
            <div class="card-box">
              <div class="alert alert-primary" v-if="saveAlert === 1">{{$language('レポートを保存しました')}}</div>
              <div class="alert alert-danger" v-else-if="saveAlert === 9">{{$language('レポートの保存に失敗しました')}}</div>
              <div class="alert alert-danger" v-else-if="saveAlert === 99">{{$language('入力内容に問題があります')}}</div>
              <div class="alert alert-danger" v-if="deleteAlert">{{ deleteAlert }}</div>
              <div class="alert alert-warning" v-if="downloadAlert">{{ downloadAlert }}</div>
              <div class="btn-toolbar justify-content-end mb-0" role="toolbar">
                <a href="#/report" class="btn btn-primary waves-effect waves-light btn-back-fixed"><i
                    class="mdi mdi-arrow-left-bold"></i> {{$language('一覧')}} </a>
                <div class="d-flex align-items-center mr-0 mr-md-3 mb-1 mb-md-0">{{$language('公開状態')}} :
                  <button type="button" class="btn btn-success ml-1" v-if="publishState"
                    @click="PrivateReport">&nbsp;&nbsp;{{$language('公開')}}&nbsp;&nbsp;</button>
                  <button type="button" class="btn btn-secondary ml-1" v-if="!publishState"
                    @click="PublishReport">{{$language('非公開')}}</button>
                </div>
                <button type="submit" class="btn btn-primary ml-1 mb-1 mb-md-0" v-if="isValid"
                  @click="saveAlert = 99">{{$language('save')}}</button>
                <button type="button" class="btn btn-primary disabled ml-1 mb-1 mb-md-0" v-if="!isValid">{{$language('save')}}</button>
                <button type="button" class="btn btn-primary ml-1 mb-1 mb-md-0" @click="DownloadReport"
                  v-if="isEdit">{{$language('報告書を見る')}}</button>
                <button type="button" class="btn btn-primary disabled ml-1 mb-1 mb-md-0" v-if="!isEdit">{{$language('報告書を見る')}}</button>
                <button type="button" class="btn btn-danger ml-1 ml-md-5 mb-1 mb-md-0" @click="CancelDeleteReport"
                  v-if="isEdit">{{$language('削除')}}</button>
                <button type="button" class="btn btn-danger disabled ml-1 ml-md-5 mb-1 mb-md-0"
                  v-if="!isEdit">{{$language('削除')}}</button>
              </div>
            </div>
          </div>
        </div>

      </Form>
    </div>
    <div v-else>
      <code>
        {{$language('データ取得に失敗しました')}}
      </code>
    </div>

  </div> <!-- container -->
</template>

<script>

import axios from 'axios'
import router from '@/router'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { inject } from 'vue';


const { currentRoute } = router;

export default {
  name: 'ReportListCreate',
  components: {

    Form,
    Field,
    ErrorMessage,

  },
  props: {
    returnPage: String
  },
  data() {

    return {
      schema:"",
      jsonObj: [Object],
      jsonErr: false,
      isLoading: true,
      cur_building: null,
      isValid: true,
      isEdit: false,
      saveAlert: 0, // 保存結果
      saveLoading: false,
      reportId: '',
      deleteModal: false,
      deleteAlert: '',
      downloadAlert: '',
      publishState: false,
      gridLoading: false,
      gridResult: "",
      industryData: [],
      gridList: [],
      gridCount: 0,
      gridModal: false,

      userName: document.cookie.replace(/(?:(?:^|.*;\s*)name\s*=\s*([^;]*).*$)|^.*$/, "$1"),
      name_search: '',
      location_search: '',
      series: [],
      BuildingID: '',//物件ID
      summary: {
        lender: {
          company_name: "", //社名
          location: "", //所在地
          name: "", //氏名
          telephone: "", //00-0000-0000
        },
        property: {
          name: "", //物件名
          location: "", //所在地
          search_location: "", //所在地
          search_distance: 500, //所在地
          completion_date: "", //2020-01
          scale: {
            floors: '', //99
            total_floor_area: '', //9999
            standard_floor_area: '' //9999
          },
          floor: '', //10
          area: '', //999
          maintenance_fee: '', //999999
          leasing_area: '', //999
          feature: {
            bcp: "", //BCP
            nearest_station: "", //最寄り駅
            security: "", //セキュリティ
            facility_configuration: "", //施設構成
            subsidies: "" //助成措置
          },
          industry: [],

        },
        areas_of_graph: [
          {
            prefecture: "", //都道府県
            city: "", //市区
            town: "", //町
            omr_zone: "" //OMR
          }
        ],
        areas_of_list: [
          {
            prefecture: "", //都道府県
            city: "", //市区
            town: "", //町
            omr_zone: "" //OMR
          }
        ],
        parameters: [
          {
            no: 1, //1
            field: "状況", //分野
            keyword: "業績(売上高)好調", //キーワード
            summary: "", //概要
            find_out: "" //調べ方
          },
          {
            no: 2,
            field: "状況",
            keyword: "業績(売上高)不調",
            summary: "",
            find_out: ""
          },
          {
            no: 3,
            field: "エリア",
            keyword: "ターゲット物件との近接",
            summary: "",
            find_out: ""
          },
          {
            no: 4,
            field: "ビル属性",
            keyword: "現入居ビルの築年数",
            summary: "",
            find_out: ""
          },
          {
            no: 5,
            field: "ビル属性",
            keyword: "現入居ビルのグレード",
            summary: "",
            find_out: ""
          },
          {
            no: 6,
            field: "ビル属性",
            keyword: "現入居ビルの空室面積",
            summary: "",
            find_out: ""
          },
          {
            no: 7,
            field: "ビル属性",
            keyword: "現入居ビルのBOR",
            summary: "",
            find_out: ""
          },
          {
            no: 8,
            field: "ビル属性",
            keyword: "現入居ビルの使用面積",
            summary: "",
            find_out: ""
          }
        ],
        author: "", //作成者
        publish: 0 //公開フラグ1111
      },

      // 都道府県(直書き)
      prefectures: [{ id: ' ', text: '-' }, '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'],
      graph: {
        choicePrefecture: ['', '', '', '', '', '', ''],
        cities: ['', '', '', '', '', '', ''],
        choiceCity: ['', '', '', '', '', '', ''],
        streets: ['', '', '', '', '', '', '']
      },
      list: {
        choicePrefecture: [''],
        cities: [''],
        choiceCity: [''],
        streets: ['']
      },
      omrs: [''],
      maxGraphRow: false, // 上限
      showGraphArea: [{ isShow: true }],
      center: { lat: Number, lng: Number },
      address: '',
      distance: 500,
      type: currentRoute.value.query.type == undefined ? "company" : currentRoute.value.query.type,
      mapList: [],
      reload: 0,
      cad: '',
      map: null,
      google: {
        maps: {
          StreetViewPanorama: {},
          Map: {},
          MapTypeId: {},
          Marker: {},
          InfoWindow: {},
          Circle: {},
        },
      },
      markers: [],
      infowindow: [],
      curindex: "tc1",
      tableHeaders: ["Office", 'Corp Name', "Address", "Distance"],
      // company
      viewParams: {
        name: 'CorpReference',
        apiUrl: '/v1/corps_reference/',
        articleView: 'CorpReferenceArticle',
        articleParamId: 'id',
        dataType: 100,
      },
      offset: 0,
      listStrage: {
        query: '',
        limit: 100,
        paged: 1,
      },


      lan: inject('global').language_data, lan_help: inject('global').language_help_data,
      cur_lan: 'jp',
      ccenter: '',
      radius: 0,
      isPopup: false,
    }
  },
  mounted() {
    this.schema = yup.object({
      lender_company_name: yup.string().nullable().required(this.$language('必須入力です。')).max(255, '255'+this.$language('文字以内で入力してください。')).label(this.$language('社名')),
      lender_location: yup.string().nullable().required(this.$language('必須入力です。')).max(1024, '1024'+this.$language('文字以内で入力してください。')).label(this.$language('所在地')),
      lender_name: yup.string().nullable().required(this.$language('必須入力です。')).max(1000, '1000'+this.$language('文字以内で入力してください。')).label(this.$language('氏名')),
      lender_telephone: yup.string().nullable().required(this.$language('必須入力です。')).matches(/^[a-zA-Z0-9-+]*$/, this.$language('半角英数字で入力してください。')).max(100, '100'+this.$language('文字以内で入力してください。')).label(this.$language('電話番号')),
      lender_property_name: yup.string().nullable().required(this.$language('必須入力です。')).max(500, '500'+this.$language('文字以内で入力してください。')).label(this.$language('物件名')),
      lender_property_location: yup.string().nullable().required(this.$language('必須入力です。')).max(1024, '1024'+this.$language('文字以内で入力してください。')).label(this.$language('所在地')),
      lender_property_completion_date: yup.string().nullable().matches(/^[a-zA-Z0-9-/]*$/, this.$language('半角英数字で入力してください。')).max(10, '10'+this.$language('文字以内で入力してください。')).label(this.$language('竣工年月')),
      lender_property_scale_floors: yup.string().nullable().matches(/^[a-zA-Z0-9]*$/, this.$language('半角英数字で入力してください。')).max(10, '10'+this.$language('文字以内で入力してください。')).label(this.$language('規模 - 階数')),
      lender_property_scale_total_floor_area: yup.string().nullable().matches(/^[0-9.]*$/, '半角数字で入力してください。').label(this.$language('規模 - 延床面積')),
      lender_property_scale_standard_floor_area: yup.string().nullable().matches(/^[0-9.]*$/, '半角数字で入力してください。').label(this.$language('規模 - 基準階面積')),
      property_floor: yup.string().nullable().required(this.$language('必須入力です。')).matches(/^[a-zA-Z0-9-/]*$/, this.$language('半角英数字で入力してください。')).max(10, '10'+this.$language('文字以内で入力してください。')).label(this.$language('賃貸区画階数')),
      property_area: yup.string().nullable().required(this.$language('必須入力です。')).matches(/^[0-9.]*$/, '半角数字で入力してください。').label(this.$language('賃貸区画面積')),
      property_maintenance_fee: yup.string().nullable().required(this.$language('必須入力です。')).matches(/^[0-9]*$/, '半角数字で入力してください。').label(this.$language('表面共益費込')),
      //property_leasing_area: yup.string().nullable().required('必須入力です。').matches(/^[0-9.]*$/, '半角数字で入力してください。').label('想定現状使用最小面積(業種別)'),
      //property_feature_bcp: yup.string().nullable().required('必須入力です。').max(500, '500文字以内で入力してください。').label('特徴 - BCP'),
      //property_feature_nearest_station: yup.string().nullable().required('必須入力です。').max(500, '500文字以内で入力してください。').label('特徴 - 最寄駅'),
      //property_feature_security: yup.string().nullable().required('必須入力です。').max(500, '500文字以内で入力してください。').label('特徴 - セキュリティ'),
      //property_feature_facility_configuration: yup.string().nullable().required('必須入力です。').max(500, '500文字以内で入力してください。').label('特徴 - 施設構成'),
      //property_feature_subsidies: yup.string().nullable().required('必須入力です。').max(500, '500文字以内で入力してください。').label('特徴 - 助成措置'),
    });
    if (currentRoute.value.params.dataId) {
      this.reportId = currentRoute.value.params.dataId
    } else {
      this.isLoading = false
    }
    var that = this;
    // edit
    if (this.reportId) {
      this.isEdit = true,
        axios
          .get('/v1/basic-reports/' + this.reportId)
          .then(response => {
            this.BuildingID = response.data.BuildingID;
            this.summary = response.data.summary
            this.summary.property.industry = this.summary.property.industry != undefined ? this.summary.property.industry : [];
            this.address = this.summary.property.search_location;
            this.distance = this.summary.property.search_distance;
            this.industryData = response.data.industryData;
            this.loadGoogleMapsScript().then(() => {
              this.google = window.google;
              this.autoaddress()
              this.initializeMap(that);
            });
            //公開ステータス確認
            if (response.data.summary.publish === 1) {
              this.publishState = true
            } else {
              this.publishState = false
            }
            //グラフ用住所欄のアコーディオン用
            for (let i = 0; i < this.summary.areas_of_graph.length; i++) {
              this.showGraphArea[i] = { isShow: true }
              this.OnceGraphPrefectures()
              this.OnceOMRs()
              this.OnceGraphCities(i)
              this.OnceGraphTowns(i)
            }
            this.$nextTick(() => {
              this.OnceListCities(0)
              this.OnceListTowns(0)
              this.isLoading = false
            })
          })
          .catch(error => {
            this.isLoading = false
            console.log(error),
              this.jsonErr = true
          }
          );
    } else {
      this.loadGoogleMapsScript().then(() => {
        this.google = window.google;
        this.autoaddress()
        this.initializeMap(that);
      });
    }


    //omr
    axios.get('/v1/omrs')
      .then(response => {
        this.omrs = response.data.data
        this.omrs.unshift({ id: ' ', text: '-' })
      })

  },
  methods: {
    onSubmit() {
      this.saveLoading = true
      this.saveAlert = 0
      this.summary.property.search_location = this.address;
      this.summary.property.search_distance = this.distance;
      if (this.summary.property.location == '') {
        alert("必須入力所在地。")
      }
      if (this.reportId) {
        axios
          .put('/v1/basic-reports/' + this.reportId, {
            summary: this.summary
          })
          .then(() => {
            this.saveAlert = 1
            this.$nextTick(() => {
              this.saveLoading = false
            })
          }
          )
          .catch(error => {
            console.log(error)
            this.saveAlert = 9
            this.saveLoading = false
          }
          );
        this.$nextTick(() => {
          window.parent.postMessage(['loaded'], "*");
        })
      } else {
        axios
          .post('/v1/basic-reports', {
            summary: this.summary,
            name: localStorage.getItem('name'),
          })
          .then(response => {
            this.reportId = response.data.id
            this.$router.push('edit/' + this.reportId)
            this.saveAlert = 1
            this.saveLoading = false
          }
          )
          .catch(error => {
            console.log(error)
            this.saveAlert = 9
            this.saveLoading = false
          }
          );
        this.isEdit = true
        this.$nextTick(() => {
          window.parent.postMessage(['loaded'], "*");
        })
      }
    },

    SaveReport: function () {
      this.saveLoading = true
      this.saveAlert = 0
      if (this.reportId) {
        axios
          .put('/v1/basic-reports/' + this.reportId, {
            summary: this.summary
          })
          .then(() => {
            this.saveAlert = 1
            this.$nextTick(() => {
              this.saveLoading = false
            })
          }
          )
          .catch(error => {
            console.log(error)
            this.saveAlert = 9
            this.saveLoading = false
          }
          );
        this.$nextTick(() => {
          window.parent.postMessage(['loaded'], "*");
        })
      } else {
        axios
          .post('/v1/basic-reports', {
            summary: this.summary
          })
          .then(response => {
            this.reportId = response.data.id
            this.$router.push('edit/' + this.reportId)
            this.saveAlert = 1
            this.saveLoading = false
          }
          )
          .catch(error => {
            console.log(error)
            this.saveAlert = 9
            this.saveLoading = false
          }
          );
        this.isEdit = true
        this.$nextTick(() => {
          window.parent.postMessage(['loaded'], "*");
        })
      }
    },
    PrivateReport: function () {
      this.summary.publish = 0
      this.publishState = false
    },
    PublishReport: function () {
      this.summary.publish = 1
      this.publishState = true
    },
    DownloadReport: function () {

      location.href = '/#/build_info?BuildingID=' + this.BuildingID + '&tolink=report';
      /*let xmldata = ''

      if( this.reportId ) {
        axios
          .get('/v1/basic-reports/' + this.reportId + '/download/', {
            responseType: 'blob'
          })
          .then(response => (
              xmldata = response.data,
              this.$nextTick(() => {
                let blob = new Blob([xmldata])
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'report.xlsx'
                link.click()
              })
            )
          )
          .catch(error => {
              this.downloadAlert = 'ダウンロードに失敗しました。'
              console.log(error)
            }
          );
      }*/
    },
    CancelDeleteReport: function () {
      this.deleteModal = !this.deleteModal
    },
    DeleteReport: function () {
      if (this.reportId) {
        axios
          .delete('/v1/basic-reports/' + this.reportId, {
            summary: this.summary,
            author: this.userName
          })
          .then(() => {
            sessionStorage.reportPaged = 1 // 一覧の1頁目に戻す
            this.$router.push({
              name: this.returnPage,
              query: { deleteFlg: 1 }
            })
          }
          )
          .catch(error => {
            this.deleteAlert = this.$language('レポートの削除に失敗しました。')
            this.deleteModal = false
            console.log(error)
          }
          );
      } else {
        this.summary = {
          lender: {
            company_name: "", //社名
            location: "", //所在地
            name: "", //氏名
            telephone: "", //00-0000-0000
          },
          property: {
            id: '',//物件ID
            name: "", //物件名
            location: "", //所在地
            completion_date: "", //2020-01
            scale: {
              floors: '', //99
              total_floor_area: '', //9999
              standard_floor_area: '' //9999
            },
            floor: '', //10
            area: '', //999
            maintenance_fee: '', //999999
            leasing_area: '', //999
            feature: {
              bcp: "", //BCP
              nearest_station: "", //最寄り駅
              security: "", //セキュリティ
              facility_configuration: "", //施設構成
              subsidies: "" //助成措置
            },
            industry: [],
          },
          areas_of_graph: [
            {
              prefecture: "", //都道府県
              city: "", //市区
              town: "", //町
              omr_zone: "" //OMR
            }
          ],
          areas_of_list: [
            {
              prefecture: "", //都道府県
              city: "", //市区
              town: "", //町
              omr_zone: "" //OMR
            }
          ],
          parameters: [
            {
              no: 1, //1
              field: "状況", //分野
              keyword: "業績(売上高)好調", //キーワード
              summary: "", //概要
              find_out: "" //調べ方
            },
            {
              no: 2,
              field: "状況",
              keyword: "業績(売上高)不調",
              summary: "",
              find_out: ""
            },
            {
              no: 3,
              field: "エリア",
              keyword: "ターゲット物件との近接",
              summary: "",
              find_out: ""
            },
            {
              no: 4,
              field: "ビル属性",
              keyword: "現入居ビルの築年数",
              summary: "",
              find_out: ""
            },
            {
              no: 5,
              field: "ビル属性",
              keyword: "現入居ビルのグレード",
              summary: "",
              find_out: ""
            },
            {
              no: 6,
              field: "ビル属性",
              keyword: "現入居ビルの空室面積",
              summary: "",
              find_out: ""
            },
            {
              no: 7,
              field: "ビル属性",
              keyword: "現入居ビルのBOR",
              summary: "",
              find_out: ""
            },
            {
              no: 8,
              field: "ビル属性",
              keyword: "現入居ビルの使用面積",
              summary: "",
              find_out: ""
            }
          ],
          author: "", //作成者
          publish: 0 //公開フラグ
        }
      }
    },


    //grid検索
    GridSearch: function () {
      // 入力チェック
      if (this.name_search || this.location_search) {
        this.gridLoading = true,
          axios
            .get('/v1/grids', {
              params: {
                name: this.name_search, //物件名
                address: this.location_search, //表示地址
              }
            })
            .then(response => {
              this.gridList = response.data.data
              if (response.data.count == 1) {
                this.summary.property.name = this.gridList[0].BuildingName

                this.summary.property.location = this.gridList[0].address
                this.summary.property.search_location = this.gridList[0].address
                this.address = this.gridList[0].address;
                document.querySelector("#address").value = this.address;
                this.search();
                this.summary.property.completion_date = this.gridList[0].CompletionDate
                this.summary.property.scale.floors = this.gridList[0].FlrsAbvGrnd
                this.summary.property.scale.total_floor_area = this.gridList[0].TotalGFA
                this.summary.property.scale.standard_floor_area = this.gridList[0].Typical_Minimum_FloorSize
                this.gridResult = ""
                this.gridLoading = false
              } else {
                this.gridModal = true // 選択肢を表示
                this.gridResult = ""
                this.gridLoading = false
              }
            }
            )
            // 0件
            .catch(() => {
              this.gridResult = this.$language('一致するデータが見つかりません。'),
                this.gridLoading = false
            }
            );
      } else {
        this.gridResult = this.$language('物件名と所在地を入力してください。')
      }
    },
    //Grid選択モーダルを閉じる
    CancelGridReport: function () {
      this.gridModal = false
    },
    //Grid選択モーダルのデータ選択
    SelectGridDataRow: function (num) {
      this.summary.property.name = this.gridList[num].BuildingName
      this.summary.property.search_location = this.gridList[num].address
      this.summary.property.location = this.gridList[num].address
      this.address = this.gridList[num].address;
      document.querySelector("#address").value = this.address;
      this.search();
      this.summary.property.completion_date = this.gridList[num].CompletionDate
      this.summary.property.scale.floors = this.gridList[num].FlrsAbvGrnd
      this.summary.property.feature.nearest_station = (this.gridList[num].PublicTransportationMetro_1 != null ? this.gridList[num].PublicTransportationMetro_1 : '') + " " + (this.gridList[num].PublicTransportationMetro_2 != null ? this.gridList[num].PublicTransportationMetro_2 : '') + ' ' + (this.gridList[num].PublicTransportationMetro_3 != null ? this.gridList[num].PublicTransportationMetro_3 : '')
      this.summary.property.scale.total_floor_area = this.gridList[num].TotalGFA
      this.summary.property.scale.standard_floor_area = this.gridList[num].Typical_Minimum_FloorSize
      this.gridModal = false
    },


    //グラフ用 市町村取得
    GraphCities: function (val) {
      if (this.summary.areas_of_graph[val].prefecture !== ' ') {
        if (this.graph.choicePrefecture[val] !== this.summary.areas_of_graph[val].prefecture) {
          this.graph.choicePrefecture[val] = this.summary.areas_of_graph[val].prefecture
          this.graph.choiceCity[val] = ''
          this.summary.areas_of_graph[val].city = ''
          this.graph.streets[val] = []
          this.summary.areas_of_graph[val].town = ''
          axios
            .get('/v1/cities', {
              params: {
                state: this.summary.areas_of_graph[val].prefecture,
              }
            })
            .then(response => {
              this.graph.cities[val] = response.data.data
              this.graph.cities[val].unshift({ id: ' ', text: '-' })
            }
            )
            .catch(() => {
              console.log('Not applicable')
              // this.jsonErr = true
            }
            );
        }
      } else {
        this.graph.choicePrefecture[val] = ''
        this.graph.choiceCity[val] = ''
        this.graph.cities[val] = [{ id: ' ', text: '-' }]
        this.summary.areas_of_graph[val].city = ''
        this.graph.streets[val] = [{ id: ' ', text: '-' }]
        this.summary.areas_of_graph[val].town = ''
      }
    },
    //グラフ用 町取得
    GraphTowns: function (val) {
      if (this.summary.areas_of_graph[val].city) {
        if (this.graph.choiceCity[val] !== this.summary.areas_of_graph[val].city) {
          this.graph.choiceCity[val] = this.summary.areas_of_graph[val].city
          this.summary.areas_of_graph[val].town = ''
          axios
            .get('/v1/streets', {
              params: {
                state: this.summary.areas_of_graph[val].prefecture,
                city: this.summary.areas_of_graph[val].city,
              }
            })
            .then(response => {
              this.graph.streets[val] = response.data.data
              this.graph.streets[val].unshift({ id: ' ', text: '-' })
            }
            )
            .catch(() => {
              console.log('Not applicable')
              // this.jsonErr = true
            }
            );
        }
      } else {
        this.graph.choiceCity[val] = ''
        this.graph.streets[val] = [{ id: ' ', text: '-' }]
        this.summary.areas_of_graph[val].town = ''
      }
    },
    //グラフ用 初回 都道府県取得(既にデータがある場合)
    OnceGraphPrefectures: function () {
      axios
        .get('/v1/states')
        .then(response => {
          this.prefectures = response.data.data
          this.prefectures.unshift({ id: ' ', text: '-' })
        }
        )
        .catch(() => {
          console.log('Not applicable')
          // this.jsonErr = true
        }
        );
    },
    //グラフ用 初回 市町村取得(既にデータがある場合)
    OnceGraphCities: function (val) {
      if (!this.graph.cities[val] && this.summary.areas_of_graph[val].prefecture) {
        axios
          .get('/v1/cities', {
            params: {
              state: this.summary.areas_of_graph[val].prefecture,
            }
          })
          .then(response => {
            this.graph.cities[val] = response.data.data
            this.graph.cities[val].unshift({ id: ' ', text: '-' })
          }
          )
          .catch(() => {
            console.log('Not applicable')
            // this.jsonErr = true
          }
          );
      }
    },
    //グラフ用 初回 町取得(既にデータがある場合)
    OnceGraphTowns: function (val) {
      if (!this.graph.streets[val] && this.summary.areas_of_graph[val].city) {
        axios
          .get('/v1/streets', {
            params: {
              state: this.summary.areas_of_graph[val].prefecture,
              city: this.summary.areas_of_graph[val].city,
            }
          })
          .then(response => {
            this.graph.streets[val] = response.data.data
            this.graph.streets[val].unshift({ id: ' ', text: '-' })
          }
          )
          .catch(() => {
            console.log('Not applicable')
            // this.jsonErr = true
          }
          );
      }
    },
    //グラフ用 初回 OMR取得(既にデータがある場合)
    OnceOMRs: function () {
      axios
        .get('/v1/omrs')
        .then(response => {
          this.omrs = response.data.data
          this.omrs.unshift({ id: ' ', text: '-' })
        }
        )
        .catch(() => {
          console.log('Not applicable')
          // this.jsonErr = true
        }
        );

    },
    //グラフ用 住所行削除
    DelGraphRow: function (val) {
      this.summary.areas_of_graph.splice(val, 1)
      this.showGraphArea.splice(val, 1)

      // 0件になる場合は空行を追加して1件に戻す
      if (this.summary.areas_of_graph.length === 0) {
        this.summary.areas_of_graph.push(
          {
            prefecture: "", //都道府県
            city: "", //市区
            town: "", //町
            omr_zone: "" //OMR
          }
        )
        this.showGraphArea.push({ isShow: true })
      }

      this.$nextTick(() => {
        window.parent.postMessage(['loaded'], "*");
      })
    },
    //グラフ用 住所行追加
    AddGraphRow: function () {
      this.summary.areas_of_graph.push(
        {
          prefecture: "", //都道府県
          city: "", //市区
          town: "", //町
          omr_zone: "" //OMR
        }
      )
      this.showGraphArea.push({ isShow: true })
      this.$nextTick(() => {
        window.parent.postMessage(['loaded'], "*");
      })
    },
    //アコーディオン
    ToggleGraphRow: function (num) {
      if (this.showGraphArea[num]) {
        this.showGraphArea[num].isShow = !this.showGraphArea[num].isShow
      } else {
        this.showGraphArea[num] = { isShow: true }
      }
      this.$nextTick(() => {
        window.parent.postMessage(['loaded'], "*");
      })
    },
    //事例リスト用 市町村取得
    ListCities: function (val) {
      if (this.summary.areas_of_list[val].prefecture) {
        if (this.list.choicePrefecture[val] !== this.summary.areas_of_list[val].prefecture) {
          this.list.choicePrefecture[val] = this.summary.areas_of_list[val].prefecture
          this.list.choiceCity[val] = ''
          this.summary.areas_of_list[val].city = ''
          this.list.streets[val] = []
          this.summary.areas_of_list[val].town = ''
          axios
            .get('/v1/cities', {
              params: {
                state: this.summary.areas_of_list[val].prefecture,
              }
            })
            .then(response => {
              this.list.cities[val] = response.data.data
              this.list.cities[val].unshift({ id: ' ', text: '-' })
            }
            )
            .catch(() => {
              console.log('Not applicable')
              // this.jsonErr = true
            }
            );
        }
      } else {
        this.list.choicePrefecture[val] = ''
        this.list.choiceCity[val] = ''
        this.list.cities[val] = [{ id: ' ', text: '-' }]
        this.summary.areas_of_list[val].city = ''
        this.list.streets[val] = [{ id: ' ', text: '-' }]
        this.summary.areas_of_list[val].town = ''
      }
    },
    //事例リスト用 町取得
    ListTowns: function (val) {
      if (this.summary.areas_of_list[val].city) {
        if (this.list.choiceCity[val] !== this.summary.areas_of_list[val].city) {
          this.list.choiceCity[val] = this.summary.areas_of_list[val].city
          this.summary.areas_of_list[val].town = ''
          axios
            .get('/v1/streets', {
              params: {
                state: this.summary.areas_of_list[val].prefecture,
                city: this.summary.areas_of_list[val].city,
              }
            })
            .then(response => {
              this.list.streets[val] = response.data.data
              this.list.streets[val].unshift({ id: ' ', text: '-' })
            }
            )
            .catch(() => {
              console.log('Not applicable')
              // this.jsonErr = true
            }
            );
        }
      } else {
        this.list.choiceCity[val] = ''
        this.list.streets[val] = [{ id: ' ', text: '-' }]
        this.summary.areas_of_list[val].town = ''
      }
    },
    //事例リスト用 初回 市町村取得(既にデータがある場合)
    OnceListCities: function (val) {
      if (!this.list.cities[val] && this.summary.areas_of_list[val].prefecture) {
        axios
          .get('/v1/cities', {
            params: {
              state: this.summary.areas_of_list[val].prefecture,
            }
          })
          .then(response => {
            this.list.cities[val] = response.data.data
            this.list.cities[val].unshift({ id: ' ', text: '-' })
          }
          )
          .catch(() => {
            console.log('Not applicable')
            // this.jsonErr = true
          }
          );
      }
    },
    //事例リスト用 初回 町取得(既にデータがある場合)
    OnceListTowns: function (val) {
      if (!this.list.streets[val] && this.summary.areas_of_list[val].city) {
        axios
          .get('/v1/streets', {
            params: {
              state: this.summary.areas_of_list[val].prefecture,
              city: this.summary.areas_of_list[val].city,
            }
          })
          .then(response => {
            this.list.streets[val] = response.data.data
            this.list.streets[val].unshift({ id: ' ', text: '-' })
          }
          )
          .catch(() => {
            console.log('Not applicable')
            // this.jsonErr = true
          }
          );
      }
    },
    // //事例リスト用 住所行削除
    // DelListRow: function(val) {
    //   this.summary.areas_of_list.splice(val,1)
    // },
    // //事例リスト用 住所行追加
    // AddListRow: function() {
    //   this.summary.areas_of_list.push(
    //     {
    //       prefecture: "", //都道府県
    //       city: "", //市区
    //       town: "", //町
    //       omr_zone: "" //OMR
    //     }
    //   )
    // }
    initdraw(map) {
      var _that = this;
      var drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType.CIRCLE,
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [window.google.maps.drawing.OverlayType.CIRCLE],
        },

        circleOptions: {
          strokeColor: "#003f2d",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#003f2d",
          fillOpacity: 0.35,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      });

      drawingManager.setMap(map);
      window.google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {
        if (event.type == 'circle') {
          _that.ccenter = JSON.parse(JSON.stringify(event.overlay.getCenter()));
          _that.radius = parseInt(event.overlay.getRadius());
          _that.distance = _that.radius;
          console.log(_that.radius + ',' + _that.distance + "<1>")
          new window.google.maps.Geocoder().geocode({ location: event.overlay.getCenter() }, function (results, status) {
            console.log(results);
            console.log(_that.ccenter);
            if (status === "OK" && results[0]) {

              _that.address = results[0].formatted_address;
              document.querySelector("#address").value = _that.address;
              _that.radius = parseInt(event.overlay.getRadius());
              _that.distance = _that.radius;
              console.log(_that.radius + ',' + _that.distance + "<1.5>")
              _that.search();
              window.google.maps.event.addListener(event.overlay, 'radius_changed', function () {
                _that.radius = parseInt(event.overlay.getRadius());
                _that.distance = _that.radius;
                console.log(_that.radius + ',' + _that.distance + "<2>")
                _that.search();
              })
            }
          });
        }

      });
    },
    autoaddress() {

      let address1Field;
      address1Field = document.querySelector("#address");
      new this.google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: { country: ["jp"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
      });
      address1Field.focus();

    },

    search: function () {
      var that = this;
      this.address = document.querySelector("#address").value;
      console.log(this.ccenter)
      console.log(this.radius + ',' + this.distance + "<3>")
      axios.get(process.env.VUE_APP_API2_URL_BASE + '/getLocationByAddresRecommendation?address=' + this.address + '&center=' + (this.ccenter == '' ? '' : this.ccenter.lat + ',' + this.ccenter.lng) + '&distance=' + this.distance, { params: { id_token: localStorage.getItem('id_token') } })
        .then(response => {
          that.mapList = response.data.data;
          that.initializeMap(that);
        })
        .catch(error => (
          console.log(error)
          //that.jsonErr = true,
          //that.isLoading = false
        )
        );

    },
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google) {
          return resolve(window.google);
        }
        const script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.VUE_APP_GOOGLE_KEY +
          "&callback=initMap&libraries=places,drawing,geometry&v=weekly&channel=2";
        const head = document.querySelector("head");
        if (!head) return reject(new Error("head node is undefined"));
        head.appendChild(script);
        window.initMap = () => {
          resolve(window.google);
        };
        setTimeout(() => {
          if (!window.google) {
            reject(new Error("failed load google api"));
          }
        }, 10000);
      });
    },
    initializeMap(_that) {
      const mapContainer = _that.$el.querySelector("#googleMap");

      const { Map, MapTypeId, Marker, InfoWindow, Circle } = _that.google.maps;
      const Geocoder = new _that.google.maps.Geocoder();

      this.cad = '';
      // map create
      var zoom = 7
      if (_that.distance > 3000000) {
        zoom = 3
      } else if (_that.distance > 2000000) {
        zoom = 4
      } else if (_that.distance > 1000000) {
        zoom = 5
      } else if (_that.distance > 500000) {
        zoom = 6
      } else if (_that.distance > 200000) {
        zoom = 7
      } else if (_that.distance > 100000) {
        zoom = 8
      } else if (_that.distance > 50000) {
        zoom = 9
      } else if (_that.distance > 20000) {
        zoom = 10
      } else if (_that.distance > 10000) {
        zoom = 11
      } else if (_that.distance > 5000) {
        zoom = 12
      } else if (_that.distance > 2000) {
        zoom = 13
      } else if (_that.distance > 1000) {
        zoom = 14
      } else if (_that.distance > 500) {
        zoom = 15
      } else if (_that.distance > 200) {
        zoom = 16
      } else if (_that.distance > 100) {
        zoom = 17
      } else if (_that.distance > 50) {
        zoom = 18
      } else if (_that.distance > 20) {
        zoom = 19
      } else {
        zoom = 20;
      }
      _that.map = new Map(mapContainer, {
        zoom: zoom,
        mapTypeId: MapTypeId.ROADMAP,
      });

      let addr = '';
      if (_that.address == "") {
        addr = '東京';
      } else {
        addr = _that.address
      }
      console.log(addr);

      new Geocoder.geocode({ address: addr }, function (results, status) {
        if (status === "OK" && results[0]) {
          let locate = results[0].geometry.location;

          let center = {
            lat: parseFloat(locate.lat()),
            lng: parseFloat(locate.lng()),
          };
          if (_that.ccenter != null && _that.ccenter.lat != null) {
            center.lat = _that.ccenter.lat;
            center.lng = _that.ccenter.lng;
          }

          //_that.ccenter=center;
          // set locate
          _that.map.setCenter(center);
          var cc = new Circle({
            strokeColor: "#003f2d",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#003f2d",
            fillOpacity: 0.35,
            map: _that.map,
            center: center,
            editable: true,
            radius: parseInt(_that.distance),
          });
          _that.google.maps.event.addListener(cc, 'radius_changed', function () {
            _that.radius = parseInt(cc.getRadius());
            _that.distance = _that.radius;
            _that.search();

          });
          //_that.initdraw(_that.map)
          //_that.mapList.push({latitude:locate.lat(),longitude:locate.lng(),address:_that.address})
          _that.markers = [];
          _that.infowindow = [];
          if (_that.mapList.length > 0) {
            _that.mapList.map((obj, i) => {
              _that.infowindow[i] = new InfoWindow({
                content: '<div id="content">' +
                  '<div id="siteNotice">' +
                  "</div>" +
                  '<h3 id="firstHeading" class="firstHeading">' + (obj.BuildingName == undefined ? '' : obj.BuildingName) + '</h3>' +
                  '<div id="bodyContent">' +
                  "<p>竣工日 ：" + (obj['CompletionDate'] == undefined || obj['CompletionDate'] == 'NaT' ? '' : obj['CompletionDate'].substr(0, 10)) + "<br>住所： " + obj.Address + "</p>" +
                  "</div>" +
                  "</div>"
              });
              _that.markers[i] = new Marker({
                position: {
                  lat: parseFloat(obj.MapCenterLat),
                  lng: parseFloat(obj.MapCenterLong)
                },
                map: _that.map,
                title: obj.BuildingName == undefined ? '' : obj.BuildingName
              });
              _that.markers[i].addListener("click", () => {
                _that.infowindow[i].open({
                  anchor: _that.markers[i],
                  map: _that.map,
                  shouldFocus: false
                });
              });

            });


          }
          //pano.setPosition(_that.center);
        } else {
          console.log("failed load google api  : " + status);
          return;
        }
      });
    },

    gotowindow(i) {
      var _that = this;
      if (this.cad != '') {
        _that.infowindow[this.cad.replace('k', '')].close();
      }
      this.cad = 'k' + i;
      _that.infowindow[i].open({
        anchor: _that.markers[i],
        map: _that.map,
        shouldFocus: false
      });
    }
  }
}
</script>

<style lang="scss">

.btn-black{
  background-color: #003f2d;
  color: #fff;
}
.disabled .select2-selection--single {
  background: #dfdfdf !important;
}
</style>

<style lang="scss" scoped>
.valid-feedback,
.invalid-feedback {
  display: block !important;
}

.btn-back-fixed {
  position: fixed;
  right: 0;
  top: 16px;
  z-index: 255;
}

.apexcharts-legend.position-bottom.apexcharts-align-center,
.apexcharts-legend.position-top.apexcharts-align-center {
  width: 30.5%;
}

.modal {
  display: block;
}

.modal-bg {
  background: rgba(50, 58, 70, .5);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 0;
}

.grid-modal-dialog {
  max-width: 1000px;
  white-space: nowrap;

  & tbody tr {
    cursor: pointer;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &:hover {
      color: #343a40;
      background-color: #dae6ec;
      border-color: #d1e0e8;
    }
  }
}

@media screen and (max-width: 768px) {

  .grid-modal-dialog {
    margin: {
      left: auto;
      right: auto;
      top: 2.5rem;
    }

    max-width: 80vw;
  }

}

.google {
  height: 550px;
}

table td {
  max-width: 200px;
}

.types {
  margin-left: 10px;
}

.help {
  position: relative;
  margin-left: 10px;
  display: inline-block;
}

.help-box {
  margin-top: 10%;
  width: 60%;
}
</style>
